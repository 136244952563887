// Small devices (landscape phones, 576px and down)
export const SM = 576
// Medium devices (tablets, 768px and down)
export const MD = 768
// Large devices (desktops, 1024 and down)
export const LG = 1024
// Extra large devices (large desktops, 1200px and down)
export const XLG = 1200

export const XXL = 1440

export const MUSIC = [
  {
    title: "GUINSOO",
    img: "https://images.ams-prd.blv.cloud/eyJidWNrZXQiOiJpbWFnZXMtcHJkLTFvNHV6OXpieHd6cDF1Ym0iLCJrZXkiOiJiYTk1ZGY2ZC1hNjkyLTRiNjgtOWEwZS0yZGU4NDBlYzg3NjYiLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjQ4MCwiaGVpZ2h0Ijo0ODB9fX0=",
    release_date: "February 7, 2025",
    youtube_embed: "",
    url: "https://bfan.link/guinsoo",
  },
  {
    title: "It Goes Without Saying",
    img: "https://images.ams-prd.blv.cloud/eyJidWNrZXQiOiJpbWFnZXMtcHJkLTFvNHV6OXpieHd6cDF1Ym0iLCJrZXkiOiIyMGQyNTk3OC05MDdhLTQwNWEtODg1OS1iYTFjMzc1N2FiYjAiLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjM2MCwiaGVpZ2h0IjozNjB9fX0=",
    release_date: "October 27, 2023",
    youtube_embed: "",
    url: "https://bfan.link/antidote22",
  },
  {
    title: "It Goes Without Saying",
    img: "https://images.ams-prd.blv.cloud/eyJidWNrZXQiOiJpbWFnZXMtcHJkLTFvNHV6OXpieHd6cDF1Ym0iLCJrZXkiOiJkZTQ2NDdjZS01ZjkzLTQxNDktYWVjZi0wZTg3ZWIwNzA3N2YiLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjQ4MCwiaGVpZ2h0Ijo0ODB9fX0=",
    release_date: "September 1, 2023",
    youtube_embed: "",
    url: "https://bfan.link/it-goes-without-saying",
  },
  {
    title: "Mali Star",
    img: "https://images.ams-prd.blv.cloud/eyJidWNrZXQiOiJpbWFnZXMtcHJkLTFvNHV6OXpieHd6cDF1Ym0iLCJrZXkiOiJhMTIwNmQ5MS1iNDlmLTQ4MTktOTcxYS0xMjBjNDdkY2JjNjAiLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjQ4MCwiaGVpZ2h0Ijo0ODB9fX0=",
    release_date: "August 1, 2023",
    youtube_embed: "",
    url: "https://bfan.link/mali-star",
  },
  {
    title: "Salvador Dali",
    img: "https://images.ams-prd.blv.cloud/eyJidWNrZXQiOiJpbWFnZXMtcHJkLTFvNHV6OXpieHd6cDF1Ym0iLCJrZXkiOiJjNzFhNzFlYS1mZjAyLTQyOTAtOTk1Mi01NmQ1Y2U0MTgwYzgiLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjQ4MCwiaGVpZ2h0Ijo0ODB9fX0=",
    release_date: "February 1, 2023",
    youtube_embed: "",
    url: "https://bfan.link/salvador-dali-2",
  },
  {
    title: "Twice",
    img: "https://images.ams-prd.blv.cloud/eyJidWNrZXQiOiJpbWFnZXMtcHJkLTFvNHV6OXpieHd6cDF1Ym0iLCJrZXkiOiJkMDJlMmEzMy0xMGNmLTQ4NmItOThkNi03MTQ5MDliOWY3ODkiLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjQ4MCwiaGVpZ2h0Ijo0ODB9fX0=",
    release_date: "December 1, 2022",
    youtube_embed: "",
    url: "https://bfan.link/twice",
  },
  {
    title: "Dusty Winds",
    img: "https://i1.sndcdn.com/artworks-htWIuYiF0Nit8TYP-GBMoOA-t500x500.jpg",
    release_date: "October 1, 2022",
    youtube_embed: "",
    url: "https://fanlink.to/dustywinds22",
  },
  {
    title: "Where Do We Go - Single",
    img: "https://is1-ssl.mzstatic.com/image/thumb/Music114/v4/6f/21/09/6f21093c-8e44-95fc-718f-3778bcadbe3a/artwork.jpg/1000x1000bb.webp",
    release_date: "November 25, 2020",
    youtube_embed: "",
    url: "https://smarturl.it/5n954q",
  },
  {
    title: "Starburst - Single",
    img: "https://is3-ssl.mzstatic.com/image/thumb/Music124/v4/05/6b/22/056b22a9-b457-7c9c-eff7-8367e0b640d0/artwork.jpg/1000x1000bb.webp",
    release_date: "October 9, 2020",
    youtube_embed:
      "https://www.youtube.com/embed/-ywU94Dxurs?vq=hd1080&modestbranding=1&theme=light&color=white",
    url: "https://smarturl.it/lrk3oz",
  },
  // {
  //   title: "Lockdown - Single",
  //   img: "https://is3-ssl.mzstatic.com/image/thumb/Music113/v4/9b/85/10/9b851068-3ce5-9649-26b3-3fb79ec4df96/artwork.jpg/1000x1000bb.webp",
  //   release_date: "April 17, 2020",
  //   youtube_embed: "",
  //   url: "https://smarturl.it/zc3tqc",
  // },
  // {
  //   title: "Big Mood",
  //   img: "https://is4-ssl.mzstatic.com/image/thumb/Music123/v4/f9/2d/d0/f92dd01d-80ef-5124-72c3-754297529c15/artwork.jpg/1000x1000bb.webp",
  //   release_date: "March 25, 2020",
  //   youtube_embed: "",
  //   url: "https://smarturl.it/mlnc39",
  // },
  {
    title: "Hitting the Moon - Single",
    img: "https://is3-ssl.mzstatic.com/image/thumb/Music123/v4/d7/d1/3a/d7d13ace-1f08-8729-b221-eae063b7940b/artwork.jpg/1000x1000bb.webp",
    release_date: "February 28, 2020",
    youtube_embed: "",
    url: "https://smarturl.it/lzuadr",
  },
  {
    title: "Two Gold - Single",
    img: "https://i.scdn.co/image/ab67616d0000b2738eb7d365a78271d94b5dc89f",
    release_date: "July 5, 2019",
    youtube_embed: "",
    url: "https://smarturl.it/19df9i",
  },
  {
    title: "No Peace - Single",
    img: "https://is2-ssl.mzstatic.com/image/thumb/Music123/v4/e8/9e/3a/e89e3a59-a92c-2d49-bae8-fb2592da483e/artwork.jpg/1000x1000bb.webp",
    release_date: "April 19, 2019",
    youtube_embed: "",
    url: "https://smarturl.it/f0c378",
  },
  {
    title: "Alchemy",
    img: "https://is5-ssl.mzstatic.com/image/thumb/Music114/v4/d5/c3/86/d5c3864f-eb14-439a-7256-194e71fd97a6/artwork.jpg/1000x1000bb.webp",
    release_date: "March 15, 2019",
    youtube_embed: "",
    url: "http://hyperurl.co/o5mai0",
  },
]

export const STATS = [
  {
    number: "2,500,000",
    subtitle: "+ total streams",
  },
  // {
  //   number: "150,000",
  //   subtitle: "+ 'No Peace' streams",
  // },
  // {
  //   number: "125,000",
  //   subtitle: "+ 'Where Do We Go' views",
  // },
  {
    number: "522,000",
    subtitle: "+ social media reach",
  },
]

export const LINKS = [
  {
    id: "hero",
  },
  {
    id: "hot-video",
  },
  {
    id: "artist",
  },
  {
    id: "music",
  },
  {
    id: "stats",
  },
  {
    id: "brand",
  },
  {
    id: "social",
  },
  {
    id: "contact",
  },
]
